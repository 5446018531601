

.img-brand {
    text-align: center;
}

h1 {
    margin: 0 !important;
}

h3 {
    font-weight: 100;
}
.align-content {
    display: flex;
    align-content: center;
}
.label-content {
    margin-right: 10px;
}
.modal-container {
    position: relative;
    margin-top: 10px;
}

.del-modal {
    font-size: 25px;
    color: red;
}

.header-title {
    display: flex;
    justify-content: space-between;
}

.header-content {
    font-weight: 500;
    font-size: 20px;
}

.modal-img {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    text-align:center;
}
.brand-name {
    text-align:center;
   
}
.brand-text {
    font-weight: 500;
}

.body-modal {
    height: 90vh;
    overflow: auto;
}

.checkbox-info {
    width: 20px;
    margin-right: 3px;
}
.react-datepicker-wrapper {
    width: calc(100% - 42px)!important;
}
.react-tel-input .form-control { 
  width: 100% !important;
}

.download-app-container {
    display: flex;
    max-width: auto;
    top: 0;
    border-bottom: 1px solid lightgray;
    background: white;
    justify-content: flex-end;
    cursor: pointer;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 1;
  }
  .padding-header-content {
    padding-top: 44px;
  }
  
  .download-logo {
    width: 35px;
    border-radius: 10px;
  }
  
  .download-description {
    font-size: 12px;
    color: black;
    font-weight: 400;
  }
  .download-button {
      margin-left: 3px;
    white-space: nowrap;
    font-size: 12px;
    background: #FD7F35 !important;
    color: white !important;
    padding: 2px 5px !important;
    border-radius: 5px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
  }
  .download-title {
    font-size: 17px;
    font-weight: bold;
    color: #2e045b;
  }
  .none-padding {
      padding-right: 0px !important;
  }
  .btn:disabled { 
    opacity: 0.4 !important;
  }
  
.icon {
    right: 25px;
    top: -2px;
    position: absolute;
    padding: 10px; 
    color: #540296; 
    text-align: center;
    font-size: 20px !important;
    z-index: 1;
}
.title-member {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 6px;
}
.padding-top-title {
    padding-top: 0px !important;
    padding-bottom: 70px !important;
}
.modal-footer-fixed {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1;
    bottom: 0;
    background: white;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 111111111111 !important;
}
.row-padding-right {
    padding-right: 0px !important;
}
.row-padding-left {
    padding-left: 0px !important;
}

