@import './variables.scss';
 
body, html {
    padding: 0;
    margin: 0; 
    -webkit-overflow-scrolling: touch;
}
.layout-wrapper {
    position: absolute;
     min-width: -webkit-fill-available;
     min-height: -webkit-fill-available;
     overflow: auto;
     background-color: $background;
     -webkit-overflow-scrolling: touch;

    //  pointer-events: none;
    }
.layout-container {
    // pointer-events: auto;
}

* {
    font-size: 15px;
    font-weight: 300;
}
// .body{
//     background-color: $background;
//     width: 100vw;
//     height: 100vh;
//     overflow: auto;
// }
.screen {
    background-color: $white;
    width: 100%;
    height: 100%;
}
.text-error {
    color: $danger;
}
.text-click {
    color: $primary;
    cursor: pointer;
    text-decoration: underline;
}
i {
    text-decoration: none;
}
.loading-whole-page {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    z-index: 9999999999999;
    background: rgba(0, 0, 0, 0.2);
    align-items: center;
    justify-content: center;
}
.input-group-text {
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.input-label {
    margin-top: 2px;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 14px;
}
.require-star {
    color: $danger;
    font-weight: bold;
}
.date-hide-cursor input {
    caret-color: transparent;
}

.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    right: 0;
    bottom: 0;
    background-color: $white;
}
.avatar {
    max-width: 70px;
    max-height: 70px;
    border: 2px solid white;
    border-radius: 10px;
    margin-right: 5px;
}

.brand-container {
    max-width: 800px;
    margin: 0 auto;
}
.flex-1 {
    flex: 1;
}
.w-fitcontent {
    width: fit-content;
}
.clickable {
    cursor: pointer;
}
.font-weight-bold * {
    font-weight: bold;
}
.text-underline {
    text-decoration: underline;
}
.p-relative {
    position: relative;
}