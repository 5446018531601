
@import '../../../scss/variables.scss';

.img-brand {
    text-align: center;
}
.brand-member-banner {
    max-width: 800px;
    height: 220px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 30px;
}
.brand-body-container {
    max-width: 800px;
    margin: 0 auto;
    background: white;
    padding: 5px;
}
.brand-body-tab-container {
    display: flex;
    background: $light-gray;
    // margin: $default-margin;
    border-top-left-radius: $default-radius;
    border-top-right-radius: $default-radius;
    border: 1px solid lightgray;
    border-bottom: none;
}
.rank-item {
    display: flex;
    flex: 1;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .rank-icon {
        font-size: 22px;
    }
}