@import '../../../scss/variables.scss';

.brand-banner {
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 270px;
    min-height: 270px;
    max-width: 800px;
    margin: 0 auto;
    background-position: center;
}
.brand-body {
    background: white;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    margin-top: -25px;
    box-shadow: 0px -1px 10px -6px rgba(0, 0, 0,  0.5);
}
.brand-info-container {
    // margin-top: -25px;
    z-index: 1;
    .verified {
        position: absolute;
        width: 20px;
        bottom: 7px;
        right: 7px;
    }
}
// .sub-container {
//     // margin-top: -24px;
// }
.brand-info-sub-container {
    display: flex;
    .avatar {
        margin-top: -20px;
    }
}
.brand-features-icon {
    max-width: 40px;
}
.btn-features-container  {
    background: $background;
}
.brand-features-btn-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 8px;
    
    text-align: center;
    cursor: pointer;
}
.brand-features-btn-item .img-container {
    background-color: $white;
    border-radius: $default-radius;
    box-shadow: 2px 2px 9px 0px rgba(0 ,0 ,0 , 0.3);
    width: 60px;
    height: 60px;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.voucher-item-container {
    padding: 8px 5px;
    border-bottom: 1px solid $background;
    cursor: pointer;
}
.voucher-title {
    background-color: $background;
    border-top: 1px solid $white;
}
.voucher-img-item-container {
    border-radius: $default-radius;
    height: fit-content;
    overflow: hidden;
    width: 95px;
    height: 55px;
    background-position: center;
    background-size: cover;
    margin-right: 5px;
    position: relative;
}
.voucher-img-item {
    width: 100px;
}
.voucher-value-container {
    display: flex;
    background: $blue;
    text-align: right;
    width: fit-content;
    color: $white;
    margin-left: auto;
    * {
        font-size: 13px;
    }
    padding: 0 5px;
}
.price {
    color: $highlight;
}
.voucher-brand-avatar {
    width: 18px;
    border-radius: 5px;
    position: absolute;
    bottom:  3px;
    left: 3px;
}